import { FC, useEffect, useContext } from "react";
import { Wrapper, Body } from "./primary-layout.style";
import { PrimaryLayoutProps } from "./primary-layout.types";

import PrimaryNavigation from "../../organisms/primary-navigation/primary-navigation";
import Footer from "../../organisms/footer/footer";

import { useTranslation } from "react-i18next";

import { UserContext } from "../../../state/user-context-provider";

const PrimaryLayout: FC<PrimaryLayoutProps> = ({ children }) => {
  const { i18n } = useTranslation();
  const user = useContext(UserContext);

  useEffect(() => {
    i18n.changeLanguage(user ? user.language : "en");
  }, [i18n, user]);

  return (
    <Wrapper>
      <UserContext.Provider value={user}>
        <PrimaryNavigation />
        <Body>{children}</Body>
        <Footer />
      </UserContext.Provider>
    </Wrapper>
  );
};

export default PrimaryLayout;
