import { useMsal } from "@azure/msal-react";
import jwtDecode from "jwt-decode";
import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import { AxiosContext } from "../api/axios-instance-provider";
import { authRequest } from "../auth/auth-config";
import UserType from "../types/user";

export const UserContext = React.createContext<UserType>({} as UserType);

const UserContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<UserType>({} as UserType);
  const api = useContext(AxiosContext);
  const { instance } = useMsal();

  useEffect(() => {
    async function getUser() {
      instance.setActiveAccount(instance.getAllAccounts()[0]);
      const token = (await instance.acquireTokenSilent(authRequest))
        .accessToken;
      const userId = jwtDecode<{ oid: string }>(token).oid;
      console.log(userId);
      const response = await api.get(`/users/${userId}`);
      response.data.accessToken = token;
      setUser(response.data);
    }
    if (
      api.defaults.headers.Authorization &&
      api.defaults.headers.Authorization!.toString().length > 20
    ) {
      getUser();
    }
  }, [instance, api]);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export default UserContextProvider;
