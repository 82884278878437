import { FC, useEffect } from "react";
import { Wrapper, CardContainer } from "./error.style";
import H2 from "../../atoms/h2";
import S1 from "../../atoms/s1";
import { useTranslation } from "react-i18next";
import { MsalAuthenticationResult, useMsal } from "@azure/msal-react";

const Error: FC<MsalAuthenticationResult> = ({ error }) => {
  const { t } = useTranslation();
  const { instance } = useMsal();

  useEffect(() => {
    console.log(error?.errorMessage);
    if (error!.errorCode === "access_denied") {
      instance.loginRedirect();
    }
  }, [error, instance]);

  return (
    <Wrapper>
      <CardContainer>
        <H2>{t("An error occurred")}</H2>
        <S1>{t("Error occurred message")}</S1>
      </CardContainer>
    </Wrapper>
  );
};

export default Error;
