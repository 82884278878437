import styled from "styled-components";

const C1 = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* Basic / 800 */
  color: #222b45;
  margin: 0;
`;

export default C1;
