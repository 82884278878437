import styled from "styled-components";

export const H2 = styled.h2`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #222b45;
  margin: 0;
`;

export default H2;
