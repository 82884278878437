import styled from "styled-components";

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Error = styled.div`
  color: red;
`;

export const Wrapper = styled.div``;

export const ModalBody = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

interface StatTypes {
  color: string;
}

export const Stat = styled.div<StatTypes>`
  padding: 12px 20px;
  border-radius: 4px;
  width: 100%;
  background-color: ${({ color }) => `#${color}`};

  label {
    color: white;
  }
`;

export const StatsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 10px;
`;
