import styled from "styled-components";

export const Wrapper = styled.button`
  padding: 12px 18px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: 300ms;
  outline: none;
  font-weight: 700;

  background-color: #457b43;
  color: #ffffff;
  border: #457b43;
  text-transform: uppercase;

  :disabled {
    background-color: #e4eed4;
    color: #ffffff;
    border: #e4eed4;
    cursor: no-drop;

    :hover {
      filter: brightness(100%);
    }

    :focus {
      filter: brightness(100%);
    }

    :active {
      filter: brightness(100%);
    }
  }

  :hover {
    filter: brightness(90%);
  }

  :focus {
    filter: brightness(90%);
  }

  :active {
    filter: brightness(75%);
  }
`;
