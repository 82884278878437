import styled from "styled-components";

const H6 = styled.h6`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #222b45;
  margin: 0;
`;

export default H6;
