import React from "react";
import { createRoot } from "react-dom/client";

import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./auth/auth-config";

// Styles
import { ThemeProvider } from "styled-components";
import theme from "./styles/theme";
import GlobalStyles from "./styles/global-styles";

// Routing
import { BrowserRouter } from "react-router-dom";

// Components
import App from "./App";

// Utils
import reportWebVitals from "./reportWebVitals";

// Localization
import "./i18n";
import { MsalProvider } from "@azure/msal-react";

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");
const root = createRoot(rootElement);

const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <GlobalStyles />
          <App msalInstance={msalInstance} />
        </BrowserRouter>
      </ThemeProvider>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
