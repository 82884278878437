import { FC } from "react";
import { Wrapper, Options, Option } from "./datalist.style";
import { DatalistProps } from "./datalist.types";
import Input from "../input";

const Datalist: FC<DatalistProps> = ({
  label,
  placeholder,
  value,
  onChange,
  id,
  disabled,
  options,
  onOptionClick,
}) => {
  return (
    <Wrapper>
      <Input
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        id={id}
        label={label}
        list={`${label}-datalist`}
      />
      {options.length > 0 && (
        <Options>
          {options.map((option) => (
            <Option key={option} onClick={() => onOptionClick(option)}>
              {option}
            </Option>
          ))}
        </Options>
      )}
    </Wrapper>
  );
};

export default Datalist;
