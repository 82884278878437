import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 20px;
  width: 100%;
  margin-bottom: 40px;
`;

export const ToolTip = styled.span`
  background-color: black;
  border-radius: 4px;
  padding: 6px 8px;
  position: absolute;
  top: -35px;
  opacity: 0;
  transition: 300ms;
  pointer-events: none;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
`;

interface IconContainerProps {
  isDisabled: boolean;
}

export const WarningContainer = styled.div`
  padding: 40px;
  border: 1px dashed rgb(110, 186, 83);
  background-color: rgb(228, 238, 212);
  width: 100%;
  margin-bottom: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IconContainer = styled.div<IconContainerProps>`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ isDisabled }) => (isDisabled ? "no-drop" : "pointer")};
  position: relative;

  :hover {
    ${ToolTip} {
      opacity: 1;
    }
  }

  svg {
    width: 20px;
  }
`;

export const Flag = styled.span<FlagTypes>`
  text-transform: uppercase;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 4px;
  border-radius: 4px;
  width: 45px;
  cursor: pointer;
  text-align: center;
  cursor: ${({ disabled }) => (disabled ? "no-drop" : "pointer")};
`;

interface FlagTypes {
  disabled: boolean;
}

export const CsvFlag = styled(Flag)`
  background-color: ${({ disabled }) => (disabled ? "#e4eed4" : "#6eba53")};
  color: #ffffff;
`;

export const DocxFlag = styled(Flag)`
  background-color: ${({ disabled }) => (disabled ? "#8F9BB3" : "#42aaff")};
  color: #ffffff;
`;
