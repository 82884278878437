import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  position: relative;
`;

export const Options = styled.div`
  position: absolute;
  top: 66px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #e4e9f2;
  max-height: 400px;
  overflow-y: scroll;
  border-radius: 0 0 4px 4px;
  z-index: 100;
`;

export const Option = styled.button`
  padding: 10px 16px;
  outline: none;
  height: 42px;
  color: #8f9bb3;
  transition: 400ms;
  width: 100%;
  border-radius: 0;
  text-align: left;
  border: none;
  cursor: pointer;
  background-color: white;

  :hover {
    background-color: #f7f9fc;
  }
`;
