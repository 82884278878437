import { FC, useRef, useState } from "react";
import { Wrapper, Button } from "./file-input.style";
import { FileInputProps } from "./file-input.types";
import Label from "../../atoms/label";
import { useTranslation } from "react-i18next";

const FileInput: FC<FileInputProps> = ({ setFile, accept, label }) => {
  const [filename, setFilename] = useState("");
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const handleClick = () => {
    hiddenFileInput.current!.click();
  };

  const handleChange = (file: File) => {
    setFilename(file.name);
    setFile(file);
  };

  return (
    <Wrapper>
      <Label>{label}</Label>
      <Button onClick={handleClick}>
        <Label>
          {filename.length > 0 ? filename : t("Click to add a file")}
        </Label>
      </Button>
      <input
        type="file"
        accept={accept}
        ref={hiddenFileInput}
        onChange={(e) => handleChange(e.target.files![0])}
        style={{ display: "none" }}
      />
    </Wrapper>
  );
};

export default FileInput;
