import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const StyledInput = styled.input`
  border: 1px solid #e4e9f2;
  border-radius: 4px;
  padding: 10px 16px;
  outline: none;
  height: 42px;
  color: #8f9bb3;
`;
