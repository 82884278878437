import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #f7f9fc;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
`;

export const CardContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  width: 450px;
  gap: 20px;
  margin-top: 200px;
`;
