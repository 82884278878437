import styled from "styled-components";

export const P1 = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  /* Basic / 800 */
  color: #222b45;
  margin: 0;
`;

export default P1;
