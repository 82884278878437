import { FC } from "react";
import { Wrapper, Container, FooterLink, SignOutLink } from "./footer.style";
import { FooterProps } from "./footer.types";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import C1 from "../../atoms/c1/c1";

const Footer: FC<FooterProps> = () => {
  const { instance } = useMsal();
  const { t } = useTranslation();

  function handleSignOut() {
    instance.logoutRedirect({
      postLogoutRedirectUri: "https://www.leadsbeasts.com",
    });
  }

  return (
    <Wrapper>
      <Container>
        <C1>Leads Beasts V.O.F.</C1>
        <C1>
          <FooterLink target="_blank" href="https://www.leadsbeasts.com/faq">
            FAQ
          </FooterLink>
        </C1>
        <C1>
          <FooterLink
            target="_blank"
            href="https://www.leadsbeasts.com/contact"
          >
            Contact
          </FooterLink>
        </C1>
      </Container>
      <Container>
        <SignOutLink onClick={() => handleSignOut()}>
          {t("Sign out")}
        </SignOutLink>
        <C1>{t("All rights reserved")}</C1>
      </Container>
    </Wrapper>
  );
};

export default Footer;
