import { FC } from "react";
import { Wrapper, CardContainer } from "./loading.style";
import { LoadingProps } from "./loading.types";
import H2 from "../../atoms/h2";
import BarLoader from "react-spinners/BarLoader";
import { useTranslation } from "react-i18next";

const Loading: FC<LoadingProps> = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <CardContainer>
        <H2>{t("Loading...")}</H2>
        <BarLoader color="#63a13c" loading={true} width="100%" />
      </CardContainer>
    </Wrapper>
  );
};

export default Loading;
