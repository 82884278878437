import styled from "styled-components";

// Components
import Label from "../../atoms/label/label";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TableWrapper = styled.table`
  border-collapse: separate;
  border-spacing: 1px;
  background-color: #edf1f7;
  table-layout: auto;
  width: 100%;
`;

export const TableHeaderRow = styled.tr`
  background-color: #ffffff;
`;

export const Th = styled.th`
  text-align: left;
  padding: 16px;
`;

export const Tr = styled.tr`
  cursor: pointer;
  :nth-child(even) {
    background-color: #f7f9fc;

    :hover {
      filter: brightness(98%);
    }
  }

  :nth-child(odd) {
    background-color: #ffffff;

    :hover {
      filter: brightness(98%);
    }
  }
`;

export const Td = styled.td`
  text-align: left;
  padding: 16px;
  max-width: 100%;
  white-space: nowrap;
`;

export const Tbody = styled.tbody``;

export const TableHeader = styled(Label)`
  color: #222b45;
  font-weight: 700;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: unset;
  margin-top: 20px;
`;

export const PageSelectionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const PageSizeContainer = styled.div``;
