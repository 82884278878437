import styled from "styled-components";

import C1 from "../../atoms/c1/c1";

export const Wrapper = styled.footer`
  display: flex;
  padding: 40px;
  justify-content: space-between;
  background-color: #edf1f7;
  align-items: center;
  left: 0;
  bottom: 0;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
`;

export const FooterLink = styled.a`
  color: #6eba53;
  text-decoration: none;
`;

export const SignOutLink = styled(C1)`
  color: #6eba53;
  cursor: pointer;
`;
