import { FC } from "react";
import { Wrapper, NavItem, Logo } from "./primary-navigation.style";
import { PrimaryNavigationProps } from "./primary-navigation.types";
import { useTranslation } from "react-i18next";
import logo from "../../../media/images/logo.png";

// Components
import S1 from "../../atoms/s1/s1";

const PrimaryNavigation: FC<PrimaryNavigationProps> = () => {
  const { t } = useTranslation();

  const primaryRoutes = [
    {
      id: 0,
      label: t("Data"),
      to: "/",
      end: true,
    },
    {
      id: 2,
      label: t("Settings"),
      to: "/settings",
      end: false,
    },
  ];
  return (
    <Wrapper>
      <Logo src={logo} />
      {primaryRoutes.map((primaryRoute) => (
        <NavItem
          key={primaryRoute.id}
          to={primaryRoute.to}
          end={primaryRoute.end}
        >
          <S1>{primaryRoute.label}</S1>
        </NavItem>
      ))}
    </Wrapper>
  );
};

export default PrimaryNavigation;
