import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    fill: #6eba53;
    width: 32px;
  }
`;

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 31%;
  justify-content: space-between;
  height: fit-content;
`;
