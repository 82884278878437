import { useMsal } from "@azure/msal-react";
import axios, { AxiosInstance, CreateAxiosDefaults } from "axios";
import { createContext, FC, ReactNode, useEffect, useState } from "react";
import { authRequest } from "../auth/auth-config";

export const AxiosContext = createContext<AxiosInstance>({} as AxiosInstance);

const AxiosInstanceProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [accessToken, setAccessToken] = useState("");
  const [axiosConfig, setAxiosConfig] = useState<CreateAxiosDefaults>(
    {} as CreateAxiosDefaults
  );

  const { instance } = useMsal();

  useEffect(() => {
    instance.setActiveAccount(instance.getAllAccounts()[0]);
    async function getToken() {
      const accessToken = (await instance.acquireTokenSilent(authRequest))
        .accessToken;
      setAccessToken(accessToken);
    }

    getToken();
  }, [instance]);

  useEffect(() => {
    setAxiosConfig({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }, [accessToken]);

  return (
    <AxiosContext.Provider value={axios.create(axiosConfig)}>
      {children}
    </AxiosContext.Provider>
  );
};

export default AxiosInstanceProvider;
