import { FC, useState, useEffect, useContext } from "react";
import {
  Wrapper,
  ModalBody,
  ModalFooter,
  Stat,
  StatsWrapper,
  CheckboxContainer,
  Error,
} from "./search-details-modal.style";
import { SearchDetailsModalProps } from "./search-details-modal.types";
import Modal from "react-modal";
import H2 from "../../atoms/h2/h2";
import P1 from "../../atoms/p1/p1";
import { Button } from "akveo-component-library";
import { useTranslation } from "react-i18next";
import { AxiosContext } from "../../../api/axios-instance-provider";
import Input from "../input";
import Label from "../../atoms/label";
import FileInput from "../file-input";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    border: "none",
    padding: "40px",
    width: "500px",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

interface MailStats {
  sent: number;
  delivered: number;
  open: number;
  click: number;
  bounce: number;
}

const SearchDetailsModal: FC<SearchDetailsModalProps> = ({
  modalIsOpen,
  closeModal,
  activeRow,
  user,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fromName, setFromName] = useState("");
  const [fromAddress, setFromAddress] = useState("");
  const [replyToAddress, setReplyToAddress] = useState("");
  const [replyToName, setReplyToName] = useState("");
  const [subject, setSubject] = useState("");
  const [mailStatistics, setMailStatistics] = useState<MailStats>();
  const [copyCheckbox, setCopyCheckbox] = useState(true);
  const [copySuccess, setCopySuccess] = useState(false);
  const [copyError, setCopyError] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState<File | null>(null);
  const [
    showEmailTemplateTooLargeMessage,
    setShowEmailTemplateTooLargeMessage,
  ] = useState(false);

  const { t } = useTranslation();
  const api = useContext(AxiosContext);

  useEffect(() => {
    setFromAddress(user.email);
    if (activeRow.sendgrid_message_id) {
      async function getMailStatistics() {
        try {
          setIsLoading(true);
          const results = await api.get(
            `/users/${user.id}/searches/${activeRow.id}/mail/${activeRow.sendgrid_message_id}`
          );
          setMailStatistics(results.data);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      }

      getMailStatistics();
    }
    // if (user.email_templates) {
    //   const options: Option[] = user.email_templates.map((template) => ({
    //     label: template.template_name,
    //     value: template.template_id,
    //   }));
    //   setTemplates(options);
    // }
  }, [activeRow, user, api]);

  async function sendEmails() {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("emailTemplate", emailTemplate!);
      formData.append("fromAddress", fromAddress);
      formData.append("fromName", fromName);
      formData.append(
        "replyToAddress",
        copyCheckbox ? fromAddress : replyToAddress
      );
      formData.append("replyToName", copyCheckbox ? fromName : replyToName);
      formData.append("subject", subject);

      await api.post(
        `/users/${user.id}/searches/${activeRow.id}/send-email`,
        formData
      );
      setFromName("");
      setFromAddress("");
      setReplyToAddress("");
      setReplyToName("");
      setSubject("");
      setEmailTemplate(null);
      closeModal();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function copyToClipboard() {
    try {
      setIsLoading(true);
      const emails = await api.get(
        `/users/${user.id}/searches/${activeRow.id}/emails`
      );

      const stringToCopy = emails.data.join(", ");
      await navigator.clipboard.writeText(stringToCopy);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 3000);
    } catch (error) {
      setCopyError(true);
    } finally {
      setIsLoading(false);
    }
  }

  function handleFileChange(file: File) {
    if (file.size > 2_000_000) {
      setShowEmailTemplateTooLargeMessage(true);
      setTimeout(() => setShowEmailTemplateTooLargeMessage(false), 2000);
    } else {
      setEmailTemplate(file);
    }
  }

  return (
    <Wrapper>
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        contentLabel="Search Details Modal"
      >
        <H2>{t("Search details")}</H2>
        <ModalBody>
          {user.sendgrid_verification_status === "todo" && (
            <>
              <P1>
                {t(
                  "Your search is finished. You can send your leads an email yourself by copying all the email addresses using the button below, or complete the sender verification for us to send your leads an email. Navigate to the settings page to do so."
                )}
              </P1>
            </>
          )}
          {!activeRow.sendgrid_message_id &&
            user.sendgrid_verification_status === "completed" && (
              <>
                <P1>
                  {t(
                    "Your search is finished! Now use this form below to send your leads a mail."
                  )}
                </P1>
                <Input
                  label={t("From email")}
                  value={fromAddress}
                  onChange={(e) => setFromAddress(e.target.value)}
                  disabled={isLoading}
                  placeholder={t("Enter from address")}
                  id={"from-address-input"}
                />
                <Input
                  label={t("From name")}
                  value={fromName}
                  onChange={(e) => setFromName(e.target.value)}
                  disabled={isLoading}
                  placeholder={t("Enter from name")}
                  id={"from-name-input"}
                />
                <CheckboxContainer>
                  <input
                    id="copy-check"
                    type="checkbox"
                    checked={copyCheckbox}
                    onChange={(e) => setCopyCheckbox(e.target.checked)}
                  />
                  <Label htmlFor="copy-check">
                    {t("Replies can be directed to the same email and name")}
                  </Label>
                </CheckboxContainer>
                {!copyCheckbox && (
                  <>
                    <Input
                      label={t("reply to email")}
                      value={replyToAddress}
                      onChange={(e) => setReplyToAddress(e.target.value)}
                      disabled={isLoading}
                      placeholder={t("Enter reply to address")}
                      id={"reply-to-address-input"}
                    />
                    <Input
                      label={t("reply to name")}
                      value={replyToName}
                      onChange={(e) => setReplyToName(e.target.value)}
                      disabled={isLoading}
                      placeholder={t("Enter reply to name")}
                      id={"reply-to-name-input"}
                    />
                  </>
                )}
                <Input
                  label={t("email subject")}
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  disabled={isLoading}
                  placeholder={t("Enter email subject")}
                  id={"subject-input"}
                />
                {/* <Select
                  label={t("Email template")}
                  options={templates ?? []}
                  value={selectedTemplate}
                  onChange={(e) => setSelectedTemplate(e.target.value)}
                  placeholder={t("Select a template")}
                  disabled={false}
                  id={"template-select"}
                /> */}
                <FileInput
                  label={t("email template")}
                  accept=".html"
                  setFile={handleFileChange}
                />
                <Error>
                  {showEmailTemplateTooLargeMessage && t("HTML file too large")}
                </Error>
              </>
            )}
          {activeRow.sendgrid_message_id && (
            <>
              <P1>{t("Below you see the email results of your search. ")}</P1>
              {mailStatistics && (
                <StatsWrapper>
                  <Stat color="0095FF">
                    <Label>
                      {mailStatistics.sent} {t("sent")}
                    </Label>
                  </Stat>
                  <Stat color="FFAA00">
                    <Label>
                      {mailStatistics.delivered} {t("delivered")}
                    </Label>
                  </Stat>
                  <Stat color="00D68F">
                    <Label>
                      {mailStatistics.open} {t("opened")}
                    </Label>
                  </Stat>
                  <Stat color="00D68F">
                    <Label>
                      {mailStatistics.click} {t("clicks")}
                    </Label>
                  </Stat>
                  <Stat color="FF3D71">
                    <Label>
                      {mailStatistics.bounce} {t("bounces")}
                    </Label>
                  </Stat>
                </StatsWrapper>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {activeRow.sendgrid_message_id && (
            <Button
              styleType="primary"
              colorScheme="filled"
              onClick={() => closeModal()}
              size="medium"
              disabled={isLoading}
            >
              {t("close")}
            </Button>
          )}
          {!activeRow.sendgrid_message_id && (
            <>
              <Button
                styleType="danger"
                colorScheme="filled"
                onClick={() => closeModal()}
                size="medium"
                disabled={isLoading}
              >
                {t("Cancel")}
              </Button>
              {user.sendgrid_verification_status === "todo" && (
                <Button
                  styleType="primary"
                  colorScheme="filled"
                  onClick={() => copyToClipboard()}
                  size="medium"
                  disabled={isLoading}
                >
                  {copySuccess && t("Copied successfully")}
                  {copyError && t("Error while copying, Try again later")}
                  {!copySuccess && !copyError && t("copy")}
                </Button>
              )}
              {user.sendgrid_verification_status === "completed" && (
                <Button
                  styleType="primary"
                  colorScheme="filled"
                  onClick={() => sendEmails()}
                  size="medium"
                  disabled={
                    isLoading ||
                    !fromAddress ||
                    !fromName ||
                    !subject ||
                    !emailTemplate
                  }
                >
                  {t("Send to {{numberOfResults}} leads", {
                    numberOfResults: activeRow.places_processed,
                  })}
                </Button>
              )}
            </>
          )}
        </ModalFooter>
      </Modal>
    </Wrapper>
  );
};

export default SearchDetailsModal;
