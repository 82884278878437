import { FC } from "react";
import { Wrapper, ModalFooter, ModalBody } from "./confirmation-modal.style";
import { ConfirmationModalProps } from "./confirmation-modal.types";
import Modal from "react-modal";
import H2 from "../../atoms/h2/h2";
import P1 from "../../atoms/p1/p1";
import { Button } from "akveo-component-library";
import { useTranslation } from "react-i18next";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    border: "none",
    padding: "40px",
    width: "500px",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  modalIsOpen,
  onConfirm,
  closeModal,
  headerText,
  bodyText,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        contentLabel="Confirmation Modal"
      >
        <H2>{headerText}</H2>
        <ModalBody>
          <P1>{bodyText}</P1>
        </ModalBody>
        <ModalFooter>
          <Button
            styleType="danger"
            colorScheme="filled"
            onClick={() => closeModal()}
            size="medium"
            disabled={false}
          >
            {t("Cancel")}
          </Button>
          <Button
            styleType="primary"
            colorScheme="filled"
            onClick={() => onConfirm()}
            size="medium"
            disabled={false}
          >
            {t("Confirm")}
          </Button>
        </ModalFooter>
      </Modal>
    </Wrapper>
  );
};

export default ConfirmationModal;
