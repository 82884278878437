import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Logo = styled.img`
  position: absolute;
  height: 125px;
  left: 30px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  /* Basic / 100 */
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  height: 80px;
  position: relative;
`;

export const NavItem = styled(NavLink)`
  text-decoration: none;
  border-bottom: 2px solid white;
  height: 100%;
  padding: 12px 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    border-bottom: 2px solid #63a13c;
  }

  &.active {
    border-bottom: 2px solid #63a13c;
    p {
      font-weight: 700;
    }
  }
`;
