import { FC } from "react";
import { TableProps } from "./table.types";
import {
  Wrapper,
  TableWrapper,
  Th,
  Tr,
  Td,
  Tbody,
  TableHeader,
  PaginationContainer,
  PageSelectionContainer,
  PageSizeContainer,
  TableHeaderRow,
} from "./table.style";

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table";

import { useTranslation } from "react-i18next";

import Button from "../button";

import Label from "../../atoms/label/label";
import Select from "../select/select";
import P1 from "../../atoms/p1/p1";

import EmptyState from "../empty-state/empty-state";

import pageSizeOptions from "./page-size-options.json";

const Table: FC<TableProps> = ({ data, columns, className, onRowClick }) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const { t } = useTranslation();

  return (
    <Wrapper>
      <TableWrapper>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableHeaderRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th
                  key={header.id}
                  style={{
                    width:
                      header.getSize() !== 150 ? header.getSize() : undefined,
                  }}
                >
                  <TableHeader className={className}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHeader>
                </Th>
              ))}
            </TableHeaderRow>
          ))}
        </thead>
        {data.length > 0 && (
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id} onClick={() => onRowClick(row.original)}>
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id}>
                    <P1>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </P1>
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        )}
      </TableWrapper>
      {data.length === 0 && <EmptyState />}
      {data.length > 0 && (
        <PaginationContainer>
          <PageSizeContainer>
            <Select
              options={pageSizeOptions}
              value={table.getState().pagination.pageSize}
              onChange={(e) => table.setPageSize(Number(e.target.value))}
              disabled={false}
            />
          </PageSizeContainer>
          <PageSelectionContainer>
            <Button
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {t("Previous")}
            </Button>
            <Label>
              {`${t("Page")} ${table.getState().pagination.pageIndex + 1} ${t(
                "of"
              )} ${table.getPageCount()}`}
            </Label>
            <Button
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {t("Next")}
            </Button>
          </PageSelectionContainer>
        </PaginationContainer>
      )}
    </Wrapper>
  );
};

export default Table;
