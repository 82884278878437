import { FC, useState, useEffect, useContext, MouseEvent } from "react";
import {
  Wrapper,
  Header,
  IconContainer,
  ToolTip,
  CsvFlag,
  WarningContainer,
} from "./home.style";
import { HomeProps } from "./home.types";
import { ColumnDef } from "@tanstack/react-table";
import useSWR, { useSWRConfig } from "swr";
import { Button as DangerButton } from "akveo-component-library";
import { useTranslation } from "react-i18next";
import { Buffer } from "buffer";
import ConfirmationModal from "../../molecules/confirmation-modal";
import Button from "../../molecules/button";
import SearchDetailsModal from "../../molecules/search-details-modal";

// Components
import Table from "../../molecules/table/table";
import Input from "../../molecules/input/input";
import Loading from "../../organisms/loading";
import Datalist from "../../molecules/datalist";

// Icons
import { ReactComponent as TrashOutline } from "../../../media/icons/trash-2-outline.svg";

// API
import { UserContext } from "../../../state/user-context-provider";
import { AxiosContext } from "../../../api/axios-instance-provider";
import H6 from "../../atoms/h6";
import { useMsal } from "@azure/msal-react";

var FileSaver = require("file-saver");

interface Row {
  creation_date: string;
  file_name: string;
  id: string;
  status: string;
  industry: string;
  location: string;
}

interface IdTokenClaims {
  [index: string]: string | unknown;
}

const Home: FC<HomeProps> = () => {
  const [industry, setIndustry] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedSearchId, setSelectedSearchId] = useState("");
  const [isDeleteSearchModalOpen, setIsDeleteSearchModalOpen] = useState(false);
  const [isSearchDetailsModalOpen, setIsSearchDetailsModalOpen] =
    useState(false);
  const [searchesLeft, setSearchesLeft] = useState(1);
  const [idTokenClaims, setIdTokenClaims] = useState<IdTokenClaims>();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [activeRow, setActiveRow] = useState(null);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [locationOptions, setLocationOptions] = useState<string[]>([]);
  const [industryOptions, setIndustryOptions] = useState<string[]>([]);

  const user = useContext(UserContext);
  const api = useContext(AxiosContext);

  const { instance } = useMsal();

  const { data } = useSWR(user.id && "/users/$(userId)/searches", getSearches, {
    refreshInterval: 10000,
    fallbackData: [],
  });
  const { mutate } = useSWRConfig();

  const { t } = useTranslation();

  useEffect(() => {
    if (user.stripe_session_url) {
      window.location.href = user.stripe_session_url;
    }
  }, [user]);

  useEffect(() => {
    async function getNumberOfSearchesLeft() {
      try {
        const result = await api.get(`/users/${user.id}/searches-left`);
        setSearchesLeft(result.data.searches_left);
      } catch (error) {
        console.log(error);
      } finally {
        setIsPageLoading(false);
      }
    }

    if (user.id) {
      getNumberOfSearchesLeft();
    }
  }, [api, user.id]);

  useEffect(() => {
    mutate("/users/$(userId)/searches");
  }, [mutate]);

  useEffect(() => {
    const account = instance.getActiveAccount();
    if (account && account.idTokenClaims) {
      setIdTokenClaims(account.idTokenClaims);
    }
  }, [instance, user]);

  async function getSearchesLeft() {
    try {
      setIsPageLoading(true);
      const result = await api.get(`/users/${user.id}/searches-left`);
      setSearchesLeft(result.data.searches_left);
    } catch (error) {
      console.log(error);
    } finally {
      setIsPageLoading(false);
    }
  }

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const columns: ColumnDef<Row>[] = [
    {
      header: t("file name"),
      accessorKey: "file_name",
      cell: ({ row }) => `${row.original.industry} in ${row.original.location}`,
    },
    {
      header: t("creation date"),
      accessorKey: "creation_date",
      cell: ({ row }) =>
        row.original.creation_date
          ? `${new Date(row.original.creation_date).toLocaleString()}`
          : t("Collecting your data..."),
    },
    {
      header: t("status"),
      accessorKey: "status",
      cell: ({ row }) =>
        row.original.status ? capitalizeFirstLetter(row.original.status) : "",
    },
    {
      id: "download-csv",
      cell: ({ row }) => (
        <IconContainer isDisabled={row.original.status !== "completed"}>
          {row.original.status === "completed" && <ToolTip>Download</ToolTip>}
          <CsvFlag
            onClick={(e) =>
              row.original.status === "completed" &&
              handleDownload(
                row.original.id,
                `${row.original.industry} in ${row.original.location}`,
                "csv",
                e
              )
            }
            disabled={row.original.status !== "completed"}
          >
            csv
          </CsvFlag>
        </IconContainer>
      ),
      size: 10,
    },
    // {
    //   id: "download-docx",
    //   cell: ({ row }) => (
    //     <IconContainer isDisabled={row.original.status !== "completed"}>
    //       {row.original.status === "completed" && <ToolTip>Download</ToolTip>}
    //       <DocxFlag
    //         onClick={() =>
    //           row.original.status === "completed" &&
    //           handleDownload(
    //             row.original.id,
    //             `${row.original.industry} in ${row.original.location}`,
    //             "docx"
    //           )
    //         }
    //         disabled={row.original.status !== "completed"}
    //       >
    //         docx
    //       </DocxFlag>
    //     </IconContainer>
    //   ),
    //   size: 10,
    // },
    {
      id: "delete",
      cell: ({ row }) => (
        <IconContainer isDisabled={row.original.status !== "completed"}>
          {(row.original.status === "error" ||
            row.original.status === "completed") && <ToolTip>Delete</ToolTip>}
          {row.original.status === "error" ||
          row.original.status === "completed" ? (
            <TrashOutline
              onClick={(e) => {
                e.stopPropagation();
                setSelectedSearchId(row.original.id);
                setIsDeleteSearchModalOpen(true);
              }}
              fill="#FF3D71"
            />
          ) : (
            <TrashOutline fill="#8F9BB3" />
          )}
        </IconContainer>
      ),
      size: 10,
    },
  ];

  function handleReset() {
    setIndustry("");
    setLocation("");
  }

  async function handleDelete(searchId: string) {
    setIsLoading(true);
    await api.delete(`/users/${user.id}/searches/${searchId}`);
    setIsDeleteSearchModalOpen(false);
    await mutate("/users/$(userId)/searches");
    setIsLoading(false);
  }

  async function handleDownload(
    searchId: string,
    fileName: string,
    fileType: string,
    event: MouseEvent<HTMLSpanElement, globalThis.MouseEvent>
  ) {
    event.stopPropagation();
    const response = await api.get(
      `/users/${user.id}/searches/${searchId}/file`,
      {
        params: {
          file_type: fileType,
        },
      }
    );

    if (fileType === "csv") {
      const blob = new Blob([response.data]);

      FileSaver.saveAs(
        blob,
        `${fileName.toLowerCase().replaceAll(" ", "_")}.${fileType}`
      );
    } else {
      const blob = new Blob([Buffer.from(response.data, "base64")]);
      FileSaver.saveAs(
        blob,
        `${fileName.toLowerCase().replaceAll(" ", "_")}.${fileType}`
      );
    }
  }

  async function getSearches(url: string) {
    try {
      const res = await api.get(url.replace("$(userId)", user.id));
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }

  async function postSearch() {
    try {
      setIsLoading(true);
      await api.post(`/users/${user.id}/searches`, {
        location: location,
        industry: industry,
      });
      await mutate("/users/$(userId)/searches");
      await getSearchesLeft();
      setIndustry("");
      setLocation("");
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  function handleOnEnter(e: React.KeyboardEvent<HTMLDivElement>) {
    if (
      e.key === "Enter" &&
      !isLoading &&
      location.length > 0 &&
      industry.length > 0
    ) {
      postSearch();
    }
  }

  function handleRowClick(row: any) {
    setActiveRow(row);
    setIsSearchDetailsModalOpen(true);
  }

  function handleLocationDatalistChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setLocation(event.target.value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    setTimeoutId(
      setTimeout(() => {
        setLocationOptions([]);
        if (event.target.value) {
          setIsLoading(true);
          api
            .get(`/locations?term=${event.target.value}`)
            .then((response) => setLocationOptions(response.data))
            .catch((error) => console.log(error))
            .finally(() => setIsLoading(false));
        } else {
          setLocationOptions([]);
        }
      }, 750)
    );
  }

  function handleLocationOptionClick(value: string) {
    setLocation(value);
    setLocationOptions([]);
  }

  function handleIndustryDatalistChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setIndustry(event.target.value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    setTimeoutId(
      setTimeout(async () => {
        setIndustryOptions([]);
        if (event.target.value) {
          setIsLoading(true);
          await api
            .get(`/industries?term=${event.target.value}`)
            .then((response) => setIndustryOptions(response.data))
            .catch((error) => console.log(error))
            .finally(() => setIsLoading(false));
        } else {
          setIndustryOptions([]);
        }
      }, 750)
    );
  }

  function handleIndustryOptionClick(value: string) {
    setIndustry(value);
    setIndustryOptions([]);
  }

  return (
    <>
      {isPageLoading ? (
        <Loading />
      ) : (
        <Wrapper>
          {searchesLeft === 0 && (
            <WarningContainer>
              <H6>You have no more searches left this week.</H6>
              <Button
                onClick={() =>
                  window.open(
                    `https://billing.stripe.com/p/login/test_cN26qk33X5JO3Di7ss?prefilled_email=${idTokenClaims?.emails}`,
                    "_blank"
                  )
                }
                disabled={false}
              >
                {t("Upgrade subscription")}
              </Button>
            </WarningContainer>
          )}
          {searchesLeft > 0 && (
            <Header>
              <Datalist
                id="industry-input"
                placeholder={t("Enter an industry")}
                value={industry}
                onChange={handleIndustryDatalistChange}
                onOptionClick={handleIndustryOptionClick}
                label={t("industry")}
                disabled={isLoading}
                options={industryOptions}
              />
              <Datalist
                id="location-input"
                placeholder={t("Enter a location")}
                value={location}
                onChange={handleLocationDatalistChange}
                onOptionClick={handleLocationOptionClick}
                label={t("location")}
                disabled={isLoading}
                options={locationOptions}
              />
              <Button
                onClick={() => postSearch()}
                disabled={
                  isLoading ||
                  location.length === 0 ||
                  industry.length === 0 ||
                  searchesLeft === 0
                }
              >
                {t("search")}
              </Button>
              <DangerButton
                styleType="danger"
                colorScheme="outline"
                onClick={() => handleReset()}
                size="medium"
                disabled={
                  isLoading || location.length === 0 || industry.length === 0
                }
              >
                {t("reset")}
              </DangerButton>
            </Header>
          )}
          <Table columns={columns} data={data} onRowClick={handleRowClick} />
          <ConfirmationModal
            modalIsOpen={isDeleteSearchModalOpen}
            onConfirm={() => handleDelete(selectedSearchId)}
            closeModal={() => {
              setSelectedSearchId("");
              setIsDeleteSearchModalOpen(false);
            }}
            headerText={t("Delete search")}
            bodyText={t("Delete search confirmation")}
          />
          <SearchDetailsModal
            modalIsOpen={isSearchDetailsModalOpen}
            closeModal={() => setIsSearchDetailsModalOpen(false)}
            activeRow={activeRow ?? {}}
            user={user}
          />
        </Wrapper>
      )}
    </>
  );
};

export default Home;
