// Components
import Home from "../../../components/pages/home";
import Settings from "../../../components/pages/settings/settings";

const routes = () => [
  {
    path: "/settings",
    element: <Settings />,
  },
  {
    path: "/",
    element: <Home />,
  },
];

export default routes;
