import { FC, useState, useEffect, useContext } from "react";
import { Wrapper, Card, CardHeader } from "./settings.style";
import { AccountProps } from "./settings.types";
import { useMsal } from "@azure/msal-react";
import { ReactComponent as Person } from "../../../media/icons/person.svg";
import { ReactComponent as Globe } from "../../../media/icons/globe-2.svg";
import { ReactComponent as CreditCard } from "../../../media/icons/credit-card-outline.svg";
import { ReactComponent as Activity } from "../../../media/icons/activity-outline.svg";
import { ReactComponent as Mail } from "../../../media/icons/email-outline.svg";
import { ReactComponent as Inbox } from "../../../media/icons/inbox-outline.svg";
import { useTranslation } from "react-i18next";

import Select from "../../molecules/select";

import H6 from "../../atoms/h6/h6";
import P1 from "../../atoms/p1/p1";
import Input from "../../molecules/input/input";
import AddHtmlTemplateModal from "../../molecules/add-html-template-modal";

import Button from "../../molecules/button";
import { UserContext } from "../../../state/user-context-provider";
import { AxiosContext } from "../../../api/axios-instance-provider";

interface IdTokenClaims {
  [index: string]: string | unknown;
}

const Account: FC<AccountProps> = () => {
  const [searchesLeft, setSearchesLeft] = useState(0);
  const [idTokenClaims, setIdTokenClaims] = useState<IdTokenClaims>();
  const [language, setLanguage] = useState("");
  const [domain, setDomain] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showDomainVerificationFailure, setShowDomainVerificationFailure] =
    useState(false);
  const [isHtmlTemplateModalOpen, setIsHtmlTemplateModalOpen] = useState(false);

  const { t } = useTranslation();
  const user = useContext(UserContext);
  const api = useContext(AxiosContext);

  const { instance } = useMsal();

  // const redirectURI = `${process.env["REACT_APP_REDIRECT_URI"]}/settings`;

  // const resetPasswordURL = `https://leadsbeasts.b2clogin.com/leadsbeasts.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_password_reset&client_id=${process.env["REACT_APP_CLIENT_ID"]}&nonce=defaultNonce&redirect_uri=${redirectURI}&scope=openid&response_type=id_token&prompt=login`;

  async function postLanguage() {
    try {
      setIsLoading(true);
      await api.post(`/users/${user.id}/language`, {
        language: language,
      });
      window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function startSenderVerification() {
    try {
      setIsLoading(true);
      await api.post(`/users/${user.id}/start-sender-verification`, {
        domain,
      });
      window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function completeSenderVerification() {
    try {
      setIsLoading(true);
      const response = await api.post(
        `/users/${user.id}/complete-sender-verification`
      );
      if (response.data.isValid) {
        window.location.reload();
      } else {
        setShowDomainVerificationFailure(true);
        setTimeout(() => setShowDomainVerificationFailure(false), 2000);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setLanguage(user.language);
    const account = instance.getActiveAccount();
    if (account && account.idTokenClaims) {
      setIdTokenClaims(account.idTokenClaims);
    }
  }, [instance, user]);

  useEffect(() => {
    async function getNumberOfSearchesLeft() {
      const result = await api.get(`/users/${user.id}/searches-left`);

      setSearchesLeft(result.data.searches_left);
      setIsLoading(false);
    }

    if (user.id) {
      getNumberOfSearchesLeft();
    }
  }, [api, user.id]);

  const languages = [
    {
      value: "en",
      label: "English",
    },
    {
      value: "de",
      label: "Deutsch",
    },
    {
      value: "it",
      label: "Italian",
    },
    {
      value: "es",
      label: "Espanol",
    },
    {
      value: "fr",
      label: "Francais",
    },
    {
      value: "nl",
      label: "Nederlands",
    },
  ];

  return (
    <Wrapper>
      <Card>
        <CardHeader>
          <Globe />
          <H6>{t("Language")}</H6>
        </CardHeader>
        <Select
          label={t("Language")}
          options={languages}
          id="language-select"
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
          disabled={isLoading}
        />
        <Button onClick={() => postLanguage()} disabled={isLoading}>
          {t("Change language")}
        </Button>
      </Card>
      <Card>
        <CardHeader>
          <CreditCard />
          <H6>{t("Subscription")}</H6>
        </CardHeader>
        <P1>
          {t("Your payment details are managed in a secure Stripe portal.")}
        </P1>
        <Button
          onClick={() =>
            window.open(
              `https://billing.stripe.com/p/login/test_cN26qk33X5JO3Di7ss?prefilled_email=${idTokenClaims?.emails}`,
              "_blank"
            )
          }
          disabled={isLoading}
        >
          {t("Manage subscription")}
        </Button>
      </Card>
      <Card>
        <CardHeader>
          <Person />
          <H6>{t("Personal information")}</H6>
        </CardHeader>
        <Input
          id="-input"
          value={`${idTokenClaims?.emails}`}
          onChange={() => null}
          disabled={true}
          label={t("email")}
          placeholder="Enter your email"
        />
      </Card>
      <Card>
        <CardHeader>
          <Inbox />
          <H6>{t("Sender verification")}</H6>
        </CardHeader>
        {user.sendgrid_verification_status === "in progress" && (
          <>
            <P1>
              {t(
                "We've sent you an email with instructions on how to authenticate your domain."
              )}
            </P1>
            <Input
              id="domain-input"
              value={user.sendgrid_domain}
              onChange={() => null}
              disabled={true}
              placeholder=""
            />
            <Button
              onClick={() => completeSenderVerification()}
              disabled={isLoading}
            >
              {showDomainVerificationFailure &&
                t("Verification failed, try again")}
              {!showDomainVerificationFailure && t("Complete verification")}
            </Button>
          </>
        )}
        {user.sendgrid_verification_status === "completed" && (
          <>
            <P1>{t("Your domain has been verified.")}</P1>
            <Input
              id="domain-input"
              value={user.sendgrid_domain}
              onChange={() => null}
              disabled={true}
              placeholder=""
            />
          </>
        )}
        {user.sendgrid_verification_status === "todo" && (
          <>
            <P1>{t("Start the sender verification process below.")}</P1>
            <Input
              id="domain-name-input"
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
              disabled={false}
              label={t("domain")}
              placeholder="Enter domain"
            />
            <Button
              onClick={() => startSenderVerification()}
              disabled={!domain || isLoading}
            >
              {t("Start verification")}
            </Button>
          </>
        )}
      </Card>
      {/* {user.sendgrid_verification_status === "completed" && (
        <Card>
          <CardHeader>
            <Mail />
            <H6>{t("Email templates")}</H6>
          </CardHeader>
          <P1>{t("Upload an HTML template below.")}</P1>
          <Button
            onClick={() => setIsHtmlTemplateModalOpen(true)}
            disabled={isLoading}
          >
            {t("Start upload")}
          </Button>
        </Card>
      )} */}
      <Card>
        <CardHeader>
          <Activity />
          <H6>{t("Usage")}</H6>
        </CardHeader>
        <P1>
          {t("You have {{numberOfSearchesLeft}} searches left.", {
            numberOfSearchesLeft: searchesLeft,
          })}
        </P1>
      </Card>
      <AddHtmlTemplateModal
        modalIsOpen={isHtmlTemplateModalOpen}
        closeModal={() => setIsHtmlTemplateModalOpen(false)}
        userId={user.id ?? ""}
      />
    </Wrapper>
  );
};

export default Account;
