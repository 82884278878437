const theme = {
    fonts: {
      title: "",
      paragraph: "",
    },
    colors: {},
    borderRadius: "",
    shadow: "",
  };
  
  export default theme;
  
