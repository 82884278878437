import { FC, useContext, useState } from "react";
import {
  Wrapper,
  ModalBody,
  ModalFooter,
  Error,
} from "./add-html-template-modal.style";
import { AddHtmlTemplateModalProps } from "./add-html-template-modal.types";
import Modal from "react-modal";
import H2 from "../../atoms/h2/h2";
import { Button } from "akveo-component-library";
import { useTranslation } from "react-i18next";
import Input from "../input";
import FileInput from "../file-input";
import { AxiosContext } from "../../../api/axios-instance-provider";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    border: "none",
    padding: "40px",
    width: "500px",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

const AddHtmlTemplateModal: FC<AddHtmlTemplateModalProps> = ({
  modalIsOpen,
  closeModal,
  userId,
}) => {
  const [name, setName] = useState("");
  const [emailTemplate, setEmailTemplate] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [
    showEmailTemplateTooLargeMessage,
    setShowEmailTemplateTooLargeMessage,
  ] = useState(false);

  const { t } = useTranslation();
  const api = useContext(AxiosContext);

  async function uploadEmailTemplate() {
    try {
      const formData = new FormData();
      formData.append("file", emailTemplate!);
      setIsLoading(true);
      await api.post(`/users/${userId}/email-template`, formData, {
        headers: {
          fileName: emailTemplate!.name,
          emailName: name,
        },
      });
      setName("");
      closeModal();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    } finally {
      setEmailTemplate(null);
      setIsLoading(false);
    }
  }

  function handleFileChange(file: File) {
    if (file.size > 2_000_000) {
      setShowEmailTemplateTooLargeMessage(true);
      setTimeout(() => setShowEmailTemplateTooLargeMessage(false), 2000);
    } else {
      setEmailTemplate(file);
    }
  }

  return (
    <Wrapper>
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        contentLabel="Add HTML template Modal"
      >
        <H2>{t("Add HTML template")}</H2>
        <ModalBody>
          <Input
            label={t("name")}
            value={name}
            onChange={(e) => setName(e.target.value)}
            disabled={false}
            placeholder={t("Enter name")}
            id="name-id"
          />
          <FileInput accept=".html" setFile={handleFileChange} />
          <Error>
            {showEmailTemplateTooLargeMessage && t("HTML file too large")}
          </Error>
        </ModalBody>
        <ModalFooter>
          <Button
            styleType="danger"
            colorScheme="filled"
            onClick={() => closeModal()}
            size="medium"
            disabled={isLoading}
          >
            {t("Cancel")}
          </Button>
          <Button
            styleType="primary"
            colorScheme="filled"
            onClick={() => uploadEmailTemplate()}
            size="medium"
            disabled={isLoading || !name || !emailTemplate}
          >
            {t("Confirm")}
          </Button>
        </ModalFooter>
      </Modal>
    </Wrapper>
  );
};

export default AddHtmlTemplateModal;
