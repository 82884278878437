import { FC } from "react";
import Router from "./config/router";
import PrimaryLayout from "./components/layouts/primary-layout/primary-layout";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { authRequest } from "./auth/auth-config";
import Error from "./components/organisms/error";
import Loading from "./components/organisms/loading";
import AxiosInstanceProvider from "./api/axios-instance-provider";
import UserContextProvider from "./state/user-context-provider";

interface AppProps {
  msalInstance: PublicClientApplication;
}

const App: FC<AppProps> = ({ msalInstance }) => {
  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={Error}
        loadingComponent={Loading}
      >
        <AxiosInstanceProvider>
          <UserContextProvider>
            <PrimaryLayout>
              <Router />
            </PrimaryLayout>
          </UserContextProvider>
        </AxiosInstanceProvider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};

export default App;
