import { FC } from "react";
import { Wrapper, EmptyStateImage } from "./empty-state.style";
import { EmptyStateProps } from "./empty-state.types";

const EmptyState: FC<EmptyStateProps> = () => {
  return (
    <Wrapper>
      <EmptyStateImage />
    </Wrapper>
  );
};

export default EmptyState;
