import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Button = styled.button`
  width: 100%;
  height: 80px;
  cursor: pointer;
  border: 1px dashed rgb(110, 186, 83);
  background-color: rgb(228, 238, 212);
  border-radius: 4px;
  transition: 400ms;

  label {
    cursor: pointer;
  }

  :hover {
    filter: brightness(103%);
  }
`;
