import styled from "styled-components";

export const Label = styled.label`
  text-transform: uppercase;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* Basic / 600 */
  color: #8f9bb3;
`;

export default Label;
