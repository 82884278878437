import styled from "styled-components";

export const S1 = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  /* Basic / 800 */
  color: #222b45;
  margin: 0;
`;

export default S1;
